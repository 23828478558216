import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { CSSObjectWithLabel, GroupBase, OptionProps } from 'react-select'
import styled from 'styled-components'
import { useUIContext } from '../../../../context/UIContext'
import DayJS from '../../../../helpers/DayJS'
import { toApiDate, toViewDate } from '../../../../helpers/formatter/dateFormatters'
import { DateFilterProps } from '../../api-reports'
import { useReports } from '../../Context/ReportsContext'
import { useCohorts } from '../../Hooks/useCohorts'
import { SelectedOptions } from './CohortsSelect'

interface FilterDatesProps {
    className?: string
    setCsvQuery: Dispatch<SetStateAction<DateFilterProps>>
}

interface DateRange {
    from: string
    to: string
}

export const FilterDates = ({ className, setCsvQuery }: FilterDatesProps) => {
    const { t } = useTranslation('teamReports')
    const { toastNotification } = useUIContext()
    const { reportQuery, optionsSelected } = useReports()
    const { cohorts } = useCohorts()

    const [selectedDates, setSelectedDates] = useState<DateRange>({
        from: reportQuery.from || '',
        to: reportQuery.to || '',
    })

    const [selectedCohorts, setSelectedCohorts] = useState<SelectedOptions>(optionsSelected || [])

    const options = useMemo(
        () =>
            cohorts?.map((cohort) => ({
                label: toViewDate(cohort),
                value: toViewDate(cohort),
            })) ?? [],
        [cohorts]
    )

    const minValidDate = useMemo(() => {
        if (!reportQuery.cohorts?.length) return undefined
        return DayJS(reportQuery.cohorts[reportQuery.cohorts.length - 1]).format('YYYY-MM-DD')
    }, [reportQuery.cohorts])

    const maxValidDate = DayJS().format('YYYY-MM-DD')

    const validateDateRange = (range: DateRange): boolean => {
        const fromDate = DayJS(range.from)
        const toDate = DayJS(range.to)

        if (!fromDate.isValid() || !toDate.isValid()) {
            toastNotification(t('invalid date format'), 'error', 2)
            return false
        }

        if (fromDate.isAfter(toDate)) {
            toastNotification(t('start date must be before end date'), 'error', 2)
            return false
        }

        if (minValidDate && fromDate.isBefore(minValidDate)) {
            toastNotification(t('date cannot be before cohort start'), 'error', 2)
            return false
        }

        if (toDate.isAfter(maxValidDate)) {
            toastNotification(t('date cannot be in the future'), 'error', 2)
            return false
        }

        return true
    }

    const handleDateChange = (field: keyof DateRange, value: string) => {
        const newDates = { ...selectedDates, [field]: value }

        if (validateDateRange(newDates)) {
            setSelectedDates(newDates)
            setCsvQuery((prev) => ({
                ...prev,
                [field]: value,
            }))
        }
    }

    const setCsvQueryFn = (data: Partial<DateFilterProps>) => {
        setCsvQuery((prev) => ({
            ...prev,
            ...data,
        }))
    }

    useEffect(() => {
        if (selectedCohorts?.length === 0) {
            setCsvQueryFn({ cohorts: reportQuery.cohorts })
        } else {
            setCsvQueryFn({
                cohorts: selectedCohorts?.map(({ value }) => toApiDate(`${value}`)),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCohorts])

    const selectStyles = {
        control: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            fontSize: '14px',
            fontWeight: 'bold',
            width: '100%',
            border: '1px solid #f2f2f2',
            borderRadius: '10px',
        }),
        option: (
            baseStyles: CSSObjectWithLabel,
            state: OptionProps<
                {
                    value: string
                    label: string
                },
                true,
                GroupBase<{
                    value: string
                    label: string
                }>
            >
        ) => ({
            ...baseStyles,
            fontSize: '14px',
            backgroundColor: state.isSelected ? '#007bff' : 'white',
        }),
    }

    return (
        <FilterHeaderStyles className={className}>
            <div className="input-container">
                <Select
                    onChange={(values) => setSelectedCohorts(values)}
                    value={selectedCohorts}
                    isMulti
                    isSearchable
                    options={options}
                    placeholder={t('All Cohorts')}
                    noOptionsMessage={() => t('No options')}
                    styles={selectStyles}
                />
            </div>
            <div className="dates-filter">
                <label htmlFor="from" className="input-container">
                    <span className="upper-title">{t('from')}</span>
                    <input
                        type="date"
                        name="from"
                        id="from"
                        min={minValidDate}
                        value={selectedDates.from}
                        onChange={(e) => handleDateChange('from', e.target.value)}
                        className="rangeDateSelector"
                    />
                </label>
                <label htmlFor="to" className="input-container">
                    <span className="upper-title">{t('to')}</span>
                    <input
                        type="date"
                        name="to"
                        id="to"
                        max={maxValidDate}
                        value={selectedDates.to}
                        onChange={(e) => handleDateChange('to', e.target.value)}
                        className="rangeDateSelector"
                    />
                </label>
            </div>
        </FilterHeaderStyles>
    )
}

export const FilterHeaderStyles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .dates-filter {
        width: max-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
    }

    .input-container {
        width: 100%;
        display: grid;
        font-weight: bold;
        font-size: 14px;
    }

    .upper-title {
        text-transform: uppercase;
        margin-left: 10px;
        color: #666;
    }

    .rangeDateSelector {
        border: 1px solid #f2f2f2;
        min-width: 130px;
        text-align: center;
        padding: 0 10px;
        border-radius: 10px;
        height: 36px;
        margin: 8px 5px 5px;
        color: #073045;
        font-size: 14px;
        font-weight: 600;

        &:focus {
            outline: none;
            border-color: #007bff;
        }

        &:disabled {
            background-color: #f5f5f5;
            cursor: not-allowed;
        }
    }
`
// import { Dispatch, SetStateAction, useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next'
// import styled from 'styled-components'
// import { useUIContext } from '../../../../context/UIContext'
// import DayJS from '../../../../helpers/DayJS'
// import { useReports } from '../../Context/ReportsContext'
// import { DateFilterProps } from '../../api-reports'
// import { useCohorts } from '../../Hooks/useCohorts'
// import { toApiDate, toViewDate } from '../../../../helpers/formatter/dateFormatters'
// import { SelectedOptions } from './CohortsSelect'
// import Select from 'react-select'

// export const FilterDates = ({
//     className,
//     setCsvQuery,
// }: {
//     className?: string
//     setCsvQuery: Dispatch<SetStateAction<DateFilterProps>>
// }) => {
//     const { t } = useTranslation('teamReports')
//     const { toastNotification } = useUIContext()
//     const { reportQuery } = useReports()
//     const { cohorts: cohortsSelected, from, to } = reportQuery
//     const { cohorts } = useCohorts()
//     const options = cohorts?.map((cohort) => {
//         return { label: toViewDate(cohort), value: toViewDate(cohort) }
//     })
//     const defaultSelected = cohortsSelected?.map((cohort) => ({
//         label: toViewDate(cohort),
//         value: toViewDate(cohort),
//     }))
//     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//     const [selectDates, setSelectDates] = useState<SelectDates>({ from: from!, to: to! })
//     const [optionsSelected, setOptionsSelected] = useState<SelectedOptions>(defaultSelected)
//     const [minValidDate, setMinValidDate] = useState<string>()

//     const setCsvQueryFn = (data: Partial<DateFilterProps>) => {
//         setCsvQuery((prev) => ({
//             ...prev,
//             ...data,
//         }))
//     }

//     const handleDateChange = (field: keyof SelectDates, value: string) => {
//         const newDates = { ...selectDates, [field]: value }
//         setSelectDates(newDates)

//         const fromDate = DayJS(newDates.from)
//         const toDate = DayJS(newDates.to)

//         if (fromDate.isSameOrBefore(toDate)) {
//             setCsvQueryFn({ [field]: value })
//         } else {
//             toastNotification(t('invalid date range'), 'error', 2)
//             // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//             setSelectDates({ from: from!, to: to! })
//         }
//     }

//     // Set cohorstSelected when selectedOptions change
//     useEffect(() => {
//         if (optionsSelected?.length === 0) {
//             const cohorts = cohortsSelected.map((cohort) => ({
//                 label: toViewDate(cohort),
//                 value: toViewDate(cohort),
//             }))
//             setOptionsSelected(cohorts)
//             setCsvQueryFn({ cohorts: cohortsSelected.map((cohort) => toApiDate(cohort)) })
//         } else
//             setCsvQueryFn({
//                 cohorts: optionsSelected?.map(({ value }) => toApiDate(`${value}`)),
//             })
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [optionsSelected])

//     useEffect(() => {
//         const minValidDate =
//             cohortsSelected &&
//             DayJS(cohortsSelected[cohortsSelected.length - 1]).format('YYYY-MM-DD')
//         setMinValidDate(minValidDate)
//     }, [cohortsSelected])

//     return (
//         <FilterHeaderStyles className={`${className ? className : ''}`}>
//             <div className="input-container">
//                 <Select
//                     onChange={(values) => setOptionsSelected(values)}
//                     defaultValue={optionsSelected}
//                     isMulti
//                     isSearchable
//                     options={options}
//                     placeholder={t('All Cohorts')}
//                     noOptionsMessage={() => t('No options')}
//                     styles={{
//                         control: (baseStyles) => ({
//                             ...baseStyles,
//                             fontSize: '14px',
//                             fontWeight: 'bold',
//                             width: '100%',
//                             border: '1px solid #f2f2f2',
//                             borderRadius: '10px',
//                         }),
//                         option: (baseStyles, state) => ({
//                             ...baseStyles,
//                             fontSize: '14px',
//                             backgroundColor: state.isSelected ? '#007bff' : 'white',
//                         }),
//                     }}
//                 ></Select>
//             </div>
//             <div className="dates-filter">
//                 <label htmlFor="from" className="input-container">
//                     <span className="upper-title">{t('from')}</span>
//                     <input
//                         type="date"
//                         name="from"
//                         id="from"
//                         min={minValidDate}
//                         value={selectDates.from}
//                         onChange={(e) => handleDateChange('from', e.target.value)}
//                         className="rangeDateSelector"
//                     />
//                 </label>
//                 <label htmlFor="to" className="input-container">
//                     <span className="upper-title">{t('to')}</span>
//                     <input
//                         type="date"
//                         name="to"
//                         id="to"
//                         min={minValidDate}
//                         max={DayJS().format('YYYY-MM-DD')}
//                         value={selectDates.to}
//                         onChange={(e) => handleDateChange('to', e.target.value)}
//                         className="rangeDateSelector"
//                     />
//                 </label>
//             </div>
//         </FilterHeaderStyles>
//     )
// }

// type SelectDates = {
//     from: string
//     to: string
// }

// export const FilterHeaderStyles = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 2rem;

//     .dates-filter {
//         width: max-content;
//         display: flex;
//         justify-content: flex-start;
//         align-items: center;
//     }
//     .input-container {
//         width: 100%;
//         display: grid;
//         font-weight: bold;
//         font-size: 14px;
//     }

//     .input-container > button {
//         display: block;
//         font-size: 14px;
//     }
//     .upper-title {
//         text-transform: uppercase;
//         margin-left: 10px;
//     }

//     .rangeDateSelector {
//         border: 1px solid #f2f2f2 !important;
//         min-width: 130px;
//         text-align: center;
//         padding: 0px 10px 0px 10px;
//         border-radius: 10px;
//         min-height: 36px;
//         height: 36px !important;
//         margin: 8px 5px 5px;
//         color: #073045;
//         font-size: 14px;
//         font-weight: 600;
//     }
// `
