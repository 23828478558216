import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode, useId } from 'react'
import Styles from './DropdownButton.module.css'

export const DropdownButton = ({
    dropdownIcon = false,
    icon,
    iconActive,
    label,
    options = [],
    showDropdown = false,
    setShowDropdown,
}: DropdownButtonProps) => {
    const id = useId()

    if (!label && !icon && !dropdownIcon) return null

    if (!options.length) return null

    const currentIcon = iconActive && showDropdown ? iconActive : icon

    return (
        <div style={{ position: 'relative' }}>
            <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="icon"
                style={{ fontWeight: 700 }}
            >
                {currentIcon && <FontAwesomeIcon icon={currentIcon} />}
                {label}
                {dropdownIcon && <FontAwesomeIcon icon={['fas', 'caret-down']} />}
            </button>

            {
                // Dropdown list
                showDropdown && (
                    <span className={Styles.dropdown}>
                        {options.map(
                            (Node, index) =>
                                Node && (
                                    <div key={`${index}-${id}`} className={Styles.dropdownItem}>
                                        {Node}
                                    </div>
                                )
                        )}
                    </span>
                )
            }
        </div>
    )
}

interface DropdownButtonProps {
    showDropdown: boolean
    setShowDropdown: (showDropdown: boolean) => void
    dropdownIcon?: boolean
    icon?: IconProp
    iconActive?: IconProp
    label?: ReactNode
    options?: ReactNode[]
}
