import { useEffect, useState } from 'react'
import { ChartWrapperOptions } from 'react-google-charts'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useLocation from 'wouter/use-location'
import ConfirmModal from '../../components/ConfirmModal'
import HeaderBanner from '../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'
import { DropdownButton } from '../../components/DropdownButton'
import { NoData } from '../../components/GoogleChart/ChartComponent'
import WarningBlock from '../../components/WarningBlock'
import { useUser } from '../../context/UserContext'
import { capitalize } from '../../helpers/formatter'
import { toUtcViewDate } from '../../helpers/formatter/dateFormatters'
import { useGAEvents } from '../../hooks/useGAEvents'
import FullLayout from '../../layouts/FullLayout'
import LoadingPage from '../Other/LoadingPage'
import { DateFilterProps, getDailyMonitoring } from './api-reports'
import { useReports } from './Context/ReportsContext'
import { useTeam } from './Context/TeamContext'
import { useCohorts } from './Hooks/useCohorts'
import { useCsvReport } from './Hooks/useCsvReport'
import { ButtonExport } from './Reports/Components/ButtonExport'
import { CohortsSelect } from './Reports/Components/CohortsSelect'
import { FilterDates } from './Reports/Components/FilterDates'
import { Menu } from './Reports/Components/Menu'

export const Reports = () => {
    const { t } = useTranslation('teamReports')
    const { sendGA } = useGAEvents()
    const [, setLocation] = useLocation()
    const { role } = useUser()
    const { team, courseID, mainLinks } = useTeam()
    const { cohorts, lastMigrationDate, isLoading } = useCohorts()
    const { reportQuery } = useReports()
    const { getFilteredReport } = useCsvReport()
    const [csvQuery, setCsvQuery] = useState<DateFilterProps>({ ...reportQuery })
    const [dateSelectorModal, setDateSelectorModal] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)

    const formattedDate = toUtcViewDate(lastMigrationDate, 'HH:hh')

    const onConfirm = async () => {
        const response = await getFilteredReport(
            () => getDailyMonitoring(csvQuery),
            'dailyMonitoring'
        )
        if (response) {
            setDateSelectorModal(false)
            setShowDropdown(false)
        }
    }

    const onCancel = () => {
        setDateSelectorModal(false)
        setShowDropdown(false)
    }

    useEffect(() => {
        sendGA({ category: 'Reporting', action: 'ViewReportSection' })
    }, [team, role, sendGA])

    useEffect(() => {
        sessionStorage.setItem('last-courseId', courseID || '')
    }, [courseID])

    if (!team) return null

    const {
        hq: { name: hqName },
        teamName,
    } = team

    if (isLoading) return <LoadingPage />

    return (
        <FullLayout links={mainLinks} ruta={`${capitalize(hqName)} > ${capitalize(teamName)}`}>
            <WarningBlock />
            <ReportStyled className="container">
                <HeaderBanner
                    title={team?.teamName}
                    subtitle={t('reports')}
                    subActions={
                        <DropdownButton
                            label={t('Download')}
                            dropdownIcon={true}
                            icon={['fas', 'download']}
                            showDropdown={showDropdown}
                            setShowDropdown={setShowDropdown}
                            options={[
                                <>
                                    <ButtonExport
                                        onClick={() =>
                                            setLocation(`./${courseID}/pdf`, { replace: false })
                                        }
                                        label="PDF"
                                        disabled={!cohorts}
                                        type="outlined small"
                                        icon={['fas', 'print']}
                                    />
                                    <ButtonExport
                                        onClick={() => setDateSelectorModal(true)}
                                        label="CSV"
                                        disabled={!cohorts}
                                        type="outlined small"
                                        icon={['fas', 'file-csv']}
                                    />
                                </>,
                            ]}
                        ></DropdownButton>
                    }
                />
                <div className="span-stats">
                    {t('Last updated')} {formattedDate} hs
                </div>
                {!cohorts ? (
                    <NoData text={`You don't have cohorts yet`} />
                ) : (
                    <>
                        <CohortsSelect />
                        <Menu />
                    </>
                )}
            </ReportStyled>
            {dateSelectorModal && (
                <ConfirmModal
                    title={t('Select cohorts and date range')}
                    open={dateSelectorModal}
                    confirmButtonText="Confirm"
                    cancelButtonText="Cancel"
                    onConfirm={onConfirm}
                    onClose={onCancel}
                    contentText={<FilterDates setCsvQuery={setCsvQuery} />}
                />
            )}
        </FullLayout>
    )
}

export default Reports

export const ReportStyled = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 0;
    h2 {
        margin: 0;
    }
    & .loading-page-container {
        height: 6em;
    }
    & .cohort-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

export type PropsReports = {
    barChartOptions?: {
        width?: string
        height?: string
        options?: ChartWrapperOptions['options']
    }
    pieChartOptions?: {
        width?: string
        height?: string
        options?: ChartWrapperOptions['options']
    }
}
