import { useTranslation } from 'react-i18next'
import IntercomSDK from '@intercom/messenger-js-sdk'
import { Regions } from '@intercom/messenger-js-sdk/dist/types'

const { REACT_APP_INTERCOM_APP_ID: app_id, REACT_APP_INTERCOM_REGION } = process.env
const region = (REACT_APP_INTERCOM_REGION ?? 'eu') as Regions

// Load Intercom if it's available
if (!app_id)
    // eslint-disable-next-line no-console
    console.warn(
        'Intercom is not available in this site, please set REACT_APP_INTERCOM_APP_ID in environment variables'
    )
else IntercomSDK({ app_id, region })

export const useIntercom = () => {
    const { t } = useTranslation('intercom')
    const Client = window.Intercom

    const newMessage = (message?: string) => Client?.('showNewMessage', message)

    const chatWithSales = (message?: string) =>
        Client?.(
            'showNewMessage',
            message ?? t('Hello!\n\nI want to chat with a consultant if possible please.')
        )

    const chatWithSupport = (message?: string) =>
        Client?.('showNewMessage', message ?? t('Hello!\n\nCan you help me please.'))

    const clientUpdate = (settings: IntercomSettings) => Client?.('update', settings)

    const clientBoot = (settings: IntercomSettings) => Client?.('boot', settings)

    const openChat = () => Client?.('show')

    const trackEvent = (...params: TrackEventParams) => Client?.('trackEvent', ...params)

    return {
        Client,
        openChat,
        chatWithSales,
        chatWithSupport,
        clientUpdate,
        trackEvent,
        clientBoot,
        newMessage,
    }
}

type TrackEventParams = Parameters<IntercomCommandSignature['trackEvent']>

export interface IntercomSettings {
    // Messenger attributes
    app_id?: string | undefined
    api_base?: string | undefined
    alignment?: string | undefined
    custom_launcher_selector?: string | undefined
    hide_default_launcher?: boolean | undefined
    horizontal_padding?: number | undefined
    session_duration?: number | undefined
    vertical_padding?: number | undefined
    action_color?: string | undefined
    background_color?: string | undefined

    // Data attributes
    email?: string | undefined
    phone?: string | undefined
    created_at?: number | undefined
    name?: string | undefined
    user_id?: string | undefined
    user_hash?: string | undefined
    unsubscribed_from_emails?: boolean | undefined
    language_override?: string | undefined
    utm_campaign?: string | undefined
    utm_content?: string | undefined
    utm_medium?: string | undefined
    utm_source?: string | undefined
    utm_term?: string | undefined
    company?: IntercomCompany | undefined
    companies?: IntercomCompany[] | undefined
    avatar?: IntercomAvatar | undefined

    // Custom attributes
    [custom_attribute: string]:
        | IntercomCompany
        | IntercomCompany[]
        | IntercomAvatar
        | IntercomCustomAttribute
}

interface IntercomCommandSignature {
    boot: (settings: IntercomSettings) => void
    shutdown: () => void
    update: (settings?: IntercomSettings) => void
    hide: () => void
    show: () => void
    showMessages: () => void
    showNewMessage: (prepopulateMessage?: string) => void
    onHide: (callback: () => void) => void
    onShow: (callback: () => void) => void
    onUnreadCountChange: (callback: (unreadCount: number) => void) => void
    onActivatorClick: (callback: () => void) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackEvent: (tag?: string, metadata?: any) => void
    startTour: (tourId: number) => void
    showArticle: (articleId: number) => void
    startSurvey: (surveyId: number) => void
    reattach_activator: () => void
    showSpace: (space: string) => void
}

interface IntercomCompany {
    name: string
    id?: string | number | undefined
    company_id?: string | number | undefined
    created_at?: number | undefined
    remote_created_at?: number | undefined
    plan?: string | undefined
    monthly_spend?: number | undefined
    user_count?: number | undefined
    size?: number | undefined
    website?: string | undefined
    industry?: string | undefined
    [custom_attribute: string]: IntercomCustomAttribute
}

interface IntercomAvatar {
    type: 'avatar'
    image_url: string
}

type IntercomCustomAttribute = string | number | boolean | null | undefined
