import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useLocation from 'wouter/use-location'
import {
    generateTime,
    toUtcViewDate,
    toViewDate,
} from '../../../../helpers/formatter/dateFormatters'
import { colors } from '../../../../styles/globals/_variables'
import { useReports } from '../../Context/ReportsContext'
import { useTeam } from '../../Hooks/useTeam'
import { useSettings } from '../../Settings'
import { ButtonExport } from './ButtonExport'
import { MenuStyles } from './Menu'
import { AttendancesReports } from './Reports/AttendancesReports'
import { CooperationReports } from './Reports/CooperationReports'
import { SurveysReports } from './Reports/SurveysReports'
import { UsersReports } from './Reports/UsersReports'
import { ValuationReports } from './Reports/ValuationReports'
import DayJS from '../../../../helpers/DayJS'

export const ReportsPDF = () => {
    const { t } = useTranslation('teamReports')
    const [, setLocation] = useLocation()
    const { teamName, courseID, hq } = useTeam()
    const { schedules } = useSettings()
    const { reportQuery } = useReports()
    const schedule = (schedules?.length && schedules[0].schedules[0]) || {
        start: { hour: 0, minutes: 0 },
        end: { hour: 0, minutes: 0 },
    }

    return (
        <ReportsPDFStyled>
            <header className="no-print">
                <div className="actions-buttons">
                    <button
                        className="action-link"
                        onClick={() => setLocation(`/admin/reports/${courseID}`)}
                    >
                        <FontAwesomeIcon icon={['fal', 'chevron-left']} size="xl" />
                    </button>
                    <ButtonExport
                        onClick={() => window.print()}
                        label={t('Download PDF')}
                        disabled={false}
                    />
                </div>
            </header>
            <section className="print-container">
                <div className="filters-container">
                    <div className="page-header">
                        <div className="header-top">
                            <h1 className="page-title"> {hq?.name}</h1>
                            <div className="page-date">
                                <p className="report-date">{t('Report date')}</p>{' '}
                                {DayJS().format('DD/MM/YYYY')}
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="course-features">
                            <div className="course-feature course">
                                <FontAwesomeIcon icon={['fal', 'book']} size="3x" />
                                <div className="course-feature-text">
                                    <p className="course">{t('Course')}</p>
                                    <p className="course-name">{teamName}</p>
                                </div>
                            </div>
                            <div className="course-feature frequency">
                                <FontAwesomeIcon icon={['far', 'wave-sine']} size="3x" />
                                <div className="course-feature-text">
                                    <p className="course">{t('Frequency')}</p>
                                    <p className="course-name">
                                        {schedules?.length} {t('times a week')}
                                    </p>
                                </div>
                            </div>
                            <div className="course-feature schedule">
                                <FontAwesomeIcon icon={['far', 'clock']} size="3x" />
                                <div className="course-feature-text">
                                    <p className="course">{t('Schedule')}</p>
                                    <p className="course-name">
                                        {schedules?.length &&
                                            t(
                                                'From {{ start }} to {{ end }} hs',
                                                generateTime(
                                                    schedule?.start.hour,
                                                    schedule?.end.hour,
                                                    schedule?.start.minutes,
                                                    schedule?.end.minutes
                                                )
                                            )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filters-selected">
                        <h4>{t('Filters')}</h4>
                        <h5>{t('Cohorts')}</h5>
                        <p>{reportQuery.cohorts.map((cohort) => toViewDate(cohort)).join(', ')}</p>
                        <h5>{t('Range')}</h5>
                        <p>
                            {toUtcViewDate(`${reportQuery.from}`)}-{' '}
                            {toUtcViewDate(`${reportQuery.to}`)}
                        </p>
                    </div>
                </div>
                <MenuStyles className="menu-styles">
                    <div className="reports-container">
                        <h3 className="title">{t('Users')}</h3>
                        <UsersReports pieChartOptions={{ width: '100%' }} />
                    </div>
                    <div className="reports-container">
                        <h3 className="title">{t('Participation')}</h3>
                        <AttendancesReports pieChartOptions={{ width: '100%' }} />
                    </div>
                    <div className="reports-container">
                        <h3 className="title">{t('Valuation')}</h3>
                        <ValuationReports pieChartOptions={{ width: '100%' }} />
                    </div>
                    <div className="reports-container">
                        <h3 className="title">{t('Surveys')}</h3>
                        <SurveysReports />
                    </div>
                    <div className="reports-container">
                        <h3 className="title">{t('Cooperation')}</h3>
                        <CooperationReports />
                    </div>
                </MenuStyles>
            </section>
        </ReportsPDFStyled>
    )
}

const ReportsPDFStyled = styled.section`
    width: 26cm;
    margin: 0 auto;
    padding: 1cm;
    & > header {
        & > .actions-buttons {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
        }
        & > .print-header {
            & > .filter-dates {
                padding: 2em 0;
                & > .display-column {
                    flex-direction: column;
                    align-items: stretch;
                }
            }
        }
    }
    & > .print-container {
        margin-top: 3em;
        padding: 3em;
        border: 1px solid ${colors.borders};
        & > .filters-container {
            display: flex;
            flex-direction: column;
            gap: 2em;
            margin-bottom: 3em;
            & > .page-header {
                padding: 34px 51px;
                background: var(--lightGrey);
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                gap: 1em;
                & > .header-top {
                    display: grid;
                    grid-template-columns: auto max-content;
                    grid-gap: 3rem;
                    * {
                        margin: 0;
                    }
                    & > .page-title {
                        font-size: 3rem;
                    }
                    & > .page-date {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        font-size: 1.3rem;
                        & > p {
                            font-weight: 700;
                            text-transform: uppercase;
                        }
                    }
                }
                & > .divider {
                    width: 100%;
                    height: 1px;
                    margin: 28px 0;
                    background-color: var(--mediumGrey);
                }
                & > .course-features {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 1rem;
                    & > .course-feature {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        & > svg {
                            width: 55px;
                            height: auto;
                        }
                        & > .course-feature-text {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 0.5rem;
                            & > p {
                                margin: 0;
                                font-size: 16px;
                            }
                            & > .course {
                                text-transform: uppercase;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
            & > .filters-selected {
                padding: 1em;
                border: 1px solid ${colors.mediumGreyBg};
                border-radius: 10px;
                & > h4 {
                    margin: 18px 0;
                    font-size: 25px;
                }
                & > h5 {
                    margin: 0;
                    font-size: 18px;
                    text-transform: uppercase;
                }
                & > p {
                    font-size: 18px;
                    line-height: 2rem;
                }
            }
            & > h2 {
                text-align: left;
            }
        }
        & > .menu-styles {
            gap: 5em;
            & > .reports-container {
                .export-button {
                    display: none;
                }
                & > .title {
                    padding: 1.3rem 2rem;
                    background: var(--darkGreyEgg);
                    border-radius: 10px;
                    color: white;
                    font-size: 26px;
                    text-align: left;
                    text-transform: uppercase;
                }
                .chart {
                    & > .row-display:not(.surveys-chart) {
                        grid-template-columns: 100%;
                        grid-template-rows: 1fr auto;
                        gap: 1em;
                    }
                    & > div > div,
                    .surveys-chart {
                        border: 1px solid ${colors.borders};
                        .one-data-value {
                            font-size: 5em;
                        }
                    }
                    .surveys-chart {
                        grid-template-columns: auto 1fr;
                    }
                }
                .ranking-card-container {
                    padding: 22px 0px;
                    grid-template-columns: 9% max-content 1fr 1fr max-content auto;
                }
            }
        }
    }

    @media only print {
        margin: 0;
        padding: 0 1rem;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        @page {
            size: A4;
        }

        & > .print-container {
            width: 100%;
            margin-top: 0;
            padding: 0;
            border: unset;
            .chart {
                break-inside: avoid;
            }
            & > .menu-styles > .reports-container > .title {
                break-after: avoid;
            }
        }
    }
`
