import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import UIContext from '../../../context/UIContext'
import DayJS from '../../../helpers/DayJS'
import downloadBlob from '../../../helpers/downloadBlob'
import { useGAEvents } from '../../../hooks/useGAEvents'
import { DateFilterProps, BasicProps } from '../api-reports'
import { useReports } from '../Context/ReportsContext'
import { Error } from '../../../interfaces/error'

export const useCsvReport = () => {
    const { t } = useTranslation('teamReports')
    const { reportQuery } = useReports()
    const { courseId, cohorts } = reportQuery
    const { toastNotification } = useContext(UIContext)
    const { sendGA } = useGAEvents()

    const getReport = async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fn: (query: BasicProps) => Promise<any>,
        csvName: string
    ) => {
        await fn({ courseId, cohorts })
            .then(({ data }) => {
                toastNotification(t('loading report'), 'success')
                downloadBlob(data, `${t(csvName)}-${DayJS().format('YYYY-MM-DD_HH:mm:ss')}.csv`)
            })
            .catch((error: Error) => {
                toastNotification(
                    t(error.response.data.message ?? 'There was an error downloading the report.'),
                    'error'
                )
            })
            .finally(() =>
                sendGA({
                    category: 'Reporting',
                    action: 'GlobalReportDownload',
                })
            )
    }

    const getFilteredReport = async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fn: (query: DateFilterProps) => Promise<any>,
        csvName: string
    ) => {
        return await fn(reportQuery)
            .then(({ data }) => {
                toastNotification(t('loading report'), 'success')
                downloadBlob(data, `${t(csvName)}-${DayJS().format('YYYY-MM-DD_HH:mm:ss')}.csv`)
                return data
            })
            .catch((error: Error) => {
                toastNotification(
                    t(error.response.data.message ?? 'There was an error downloading the report.'),
                    'error'
                )
            })
            .finally(() =>
                sendGA({
                    category: 'Reporting',
                    action: 'GlobalReportDownload',
                })
            )
    }
    return { getReport, getFilteredReport }
}
